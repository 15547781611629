import * as Sentry from "@sentry/react"
import {nanoid} from "nanoid"
import posthog from "posthog-js"
import * as R from "remeda"
import {
  atomGroup,
  liveAtom,
  liveMapAtom,
  liveSetAtom,
  playerAtom,
  roundScoped,
} from "../livestate/liveAtom"
import {
  $hostId,
  $playerIds,
  myId,
  PlayerId,
  resetAtoms,
  UNINITIALIZED_HOST,
} from "../livestate/liveContext"
import {$botIds, $names, multiGameScoped} from "../livestate/playerAtoms"
import {getOrSet} from "../shared/utils/builtins"
import {computed} from "../xignal/computed"
import {ScreenName, ScreenProps} from "./App"
import {fillPromptTemplate, GeneratedImage, rpc, RpcInput, TemplateAndCompletion} from "./client"
import {numRounds, setInterstitial} from "./screens/interstitials"
import {PromiseWithStatus} from "./utils/PromiseWithStatus"
export type {ScreenName, ScreenProps}

export type SubmissionInput = {text: string}
export type ImageResult = GeneratedImage | {error: string}
export type Scores = Record<string, number>
export interface Winner {
  playerId: PlayerId
  prompt: string
  image: ImageResult
}

export const finalRound = numRounds - 1
export const screenScoped = atomGroup()

// <Atoms>
export const $rematchNum = multiGameScoped(liveAtom("rematchNum", 0))
export const $pastThemeChoosers = multiGameScoped(liveSetAtom<string>("pastThemeChoosers"))
export const $screen = liveAtom<[ScreenName, ScreenProps<ScreenName>]>("screen", ["Lobby", {}])
export const $round = liveAtom("round", -1)
export const $roundWinners = liveMapAtom<number, Winner>("roundWinners")
export const $winningRound = liveAtom<number>("winningRound")
export const $gameId = liveAtom<string>("gameId")
export const $scores = playerAtom<number>("scores")
export const $themes = liveMapAtom<number, string>("themes")
export const $submissions = roundScoped(playerAtom<SubmissionInput>("submissions"))
export const $images = roundScoped(playerAtom<ImageResult>("images"))
export const $theme = computed((watch) => watch($themes).get(watch($round)) ?? "")
export const $gameMode = multiGameScoped(liveAtom<"v1" | "v2">("gameMode", "v2"))
// </Atoms>
export const loadedImageUrls = new Map<string, PromiseWithStatus<string>>()

$round.onChange((round) => {
  Sentry.setTag("round", round)
  roundScoped.resetLocalAtoms()
})

$hostId.onChange((hostId, oldHostId) => {
  if (hostId === myId && oldHostId !== UNINITIALIZED_HOST) {
    posthog.capture("becameHost", {oldHost: oldHostId})
  }
})

export function startRound(round: number) {
  $round.set(round)
  const pastThemeChoosers = $pastThemeChoosers.get()
  const playerIds = [...$playerIds.get()]
  let pool = playerIds.filter((id) => !pastThemeChoosers.has(id))
  if (pool.length === 0) {
    $pastThemeChoosers.reset()
    pool = playerIds
  }
  const themeChooser = R.sample(pool, 1)[0]!
  $pastThemeChoosers.add(themeChooser)

  roundScoped.resetLiveAtoms()
  if ($themes.getItem(round)) {
    setInterstitial(("round" + round) as "round0", "WriteScreen", {})
  } else {
    setInterstitial(("round" + round) as "round0", "ThemeScreen", {themeChooser})
  }
}

// Preload images
$images.onItemChange((_key, image) => {
  if (image && "url" in image) void loadImage(image.url)
})

export function loadImage(url: string) {
  return getOrSet(loadedImageUrls, url, () => {
    const img = new Image()
    return new PromiseWithStatus<string>((resolve, reject) => {
      img.onload = () => {
        resolve(url)
      }
      img.onerror = () => {
        reject(Error("Failed to load image"))
      }
      img.src = url
    }).ignoreUnhandledRejection()
  })
}

export function startGame() {
  console.log("Initializing round 0")
  const gameId = nanoid(12)
  $gameId.set(gameId)
  startRound(0)
  trackGameStart(gameId)
  posthog.capture("startGame", {
    gameId: gameId,
    numBots: $botIds.size,
    numHumans: $playerIds.size,
    rematchNum: $rematchNum.get(),
  })
}

export function trackGameStart(gameId: string) {
  void rpc.startGame.mutate({
    gameId: gameId,
    numBots: $botIds.size,
    numHumans: $playerIds.size,
    rematchNum: $rematchNum.get(),
  })
}

export function setScreen<T extends ScreenName>(name: T, props: ScreenProps<T>) {
  $screen.set([name, props])
}

export function isDevMode() {
  return !!localStorage.getItem("devMode")
}
export function randomSeed() {
  return Math.floor(Math.random() * 4294967296)
}

export function imagePromiseFromResult(imageResult: ImageResult | undefined) {
  return new PromiseWithStatus<GeneratedImage>((resolve, reject) => {
    if (!imageResult) return
    if ("error" in imageResult) reject(imageResult.error)
    else resolve(imageResult)
  }).ignoreUnhandledRejection()
}

export function submitPrompt(
  playerId: PlayerId,
  text: string,
  imagePromise: Promise<GeneratedImage>,
) {
  $submissions.setItem(playerId, {text})
  imagePromise.then(
    (image): void => {
      $images.setItem(playerId, image)
    },
    (err: unknown) => {
      $images.setItem(playerId, {error: `${err}`})
    },
  )
}

export function noResponseText(playerId: PlayerId) {
  return `[${$names.getItem(playerId)} did not respond]`
}

type GenerateImageForGameProps = {isPromptGenerated: boolean; round: number; playerName: string}

export async function generateImageForGameWithTemplate(
  props: TemplateAndCompletion &
    Omit<RpcInput["generateImage"], "prompt" | "template"> &
    GenerateImageForGameProps,
): Promise<GeneratedImage> {
  const {prompt} = fillPromptTemplate(props)
  return generateImageForGame({...props, prompt, template: props.template})
}

export async function generateImageForGame(
  props: RpcInput["generateImage"] & GenerateImageForGameProps,
): Promise<GeneratedImage> {
  return await rpc.generateImage.mutate({
    ...props,
    gameId: $gameId.get(),
    gameRound: props.round,
  })
}

export function playAgain() {
  console.log("Playing again (same players)")
  resetAtoms({exclude: new Set(multiGameScoped.liveAtoms)})
  $rematchNum.set($rematchNum.get() + 1)
  setScreen("Lobby", {})
}
