import {count, map, take} from "itertools"
import {nanoid} from "nanoid"
import {useEffect, useRef} from "react"
import {useNavigate} from "react-router-dom"
import {range, sample, shuffle} from "remeda"
import Swal from "sweetalert2"
import {$hostId, $isHost, live, myId} from "../../livestate/liveContext"
import {$avatars, $botIds, $names, $playerAndBotIds, avatarUrl} from "../../livestate/playerAtoms"
import {useAtom} from "../../xignal/react"
import addBotImage from "../assets/add-bot.png"
import {Avatar, AvatarWithName} from "../AvatarIcon"
import {promptTemplatePlaceholder} from "../client"
import {HostButton} from "../components/components"
import {HowToPlayLink} from "../components/HowToPlay"
import {twButton, twRootLayout} from "../components/styles"
import {$gameMode, $themes, startGame} from "../game"
import {useAutoFocus} from "../react/hooks"
import {cn} from "../utils/css"
import {numRounds} from "./interstitials"

const AVATAR_IDS = shuffle(take(39, count(1)))

function RoomCode() {
  return (
    <div className="flex justify-center text-center mb-1.5">
      <div className="border border-black px-2 py-0.5">
        <div className="mb-[-6px] text-sm">Room Code</div>
        <div className="text-3xl leading-tight">{live.roomCode}</div>
      </div>
    </div>
  )
}

export function AvatarScreen() {
  return (
    <div>
      <RoomCode />
      <div className="text-lg pb-[10px] text-center">Choose your avatar</div>
      {/* Using px here and below because the grid spacing shouldn't depend on the font size */}
      <div className="ml-[8px]">
        {AVATAR_IDS.map((avatarId) => (
          <div key={avatarId} className="inline-block">
            <img
              className="mr-[12px] mb-[5px] border border-yellow-900 rounded-md shadow-sm shadow-black cursor-pointer"
              src={avatarUrl(avatarId)}
              width="112"
              height="112"
              onClick={() => {
                $avatars.setItem(myId, avatarId)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const twLobbyInput =
  "w-full rounded-lg border border-yellow-800 outline-none py-2 px-3 text-lg mb-2"
const twLobbyLabel = "text-lg pl-1"

export function JoinScreen() {
  const roomCodeRef = useRef<HTMLInputElement>(null)
  useAutoFocus(roomCodeRef)
  const navigate = useNavigate()

  return (
    <div className={twRootLayout}>
      <form
        action="#" // Needed for correct "Go" label on iPhone keyboard
        onSubmit={(event) => {
          event.preventDefault()
          const roomCode = roomCodeRef.current!.value.trim().toUpperCase()
          if (roomCode.length !== 4) {
            void Swal.fire({
              text: "Room code must be 4 characters",
            })
            return
          }
          navigate(`/play/${roomCode}`)
        }}
      >
        <label className={twLobbyLabel} htmlFor="roomCode">
          Room code
        </label>
        <input
          id="roomCode"
          className={cn(twLobbyInput, "uppercase")}
          ref={roomCodeRef}
          autoCapitalize="characters"
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
          maxLength={4}
        />
        <button className={twButton}>Continue</button>
      </form>
    </div>
  )
}

export function NameScreen() {
  const nameRef = useRef<HTMLInputElement>(null)
  useAutoFocus(nameRef)

  return (
    <div>
      <RoomCode />
      <form
        action="#" // Needed for correct "Go" label on iPhone keyboard
        onSubmit={(event) => {
          event.preventDefault()
          const name = nameRef.current!.value
          if (!name.trim()) {
            void Swal.fire({
              text: "Name can't be blank",
            })
            return
          }
          localStorage["name"] = name
          $names.setItem(myId, name)
        }}
      >
        <label className={twLobbyLabel} htmlFor="name">
          Name
        </label>
        <input
          type="text"
          id="name"
          defaultValue={localStorage["name"]}
          className={twLobbyInput}
          ref={nameRef}
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
        />
        <button className={twButton}>Continue</button>
      </form>
    </div>
  )
}

const botNames = [
  "Botty McBotFace",
  "Botty McBotterson",
  "Sir Bots A Lot",
  "Baby Got Bot",
  "Robot Deniro",
  "Botty Kennedy",
  "Botty Brown",
  "Botty Fischer",
  "Bot Dylan",
  "Bot Ulism",
  "Harry Botter",
  "Peanut Botter",
]

const twAdvancedButton = "mb-1"
export function Lobby(props: {}) {
  const playerIds = useAtom($playerAndBotIds)
  const hostId = useAtom($hostId)
  const hostName = useAtom($names.$item(hostId)) || "the host"
  const isHost = useAtom($isHost)
  const gameMode = useAtom($gameMode)

  useEffect(() => {
    // We don't want players to miss that the game started if they're viewing "How to play"
    return () => Swal.close()
  }, [])

  return (
    <div>
      <RoomCode />
      <div className="text-lg pb-5 mt-2 text-center italic leading-snug">
        <div>Waiting for {hostName} to start the game</div>
        <HowToPlayLink />
      </div>
      <div className="ml-[8px] mb-2">
        {map(playerIds, (playerId) => (
          <AvatarWithName
            key={playerId}
            className={$botIds.has(playerId) ? "cursor-pointer" : ""}
            playerId={playerId}
            size={112}
            onClick={() => {
              $botIds.delete(playerId)
            }}
          />
        ))}
        {isHost && (
          <Avatar
            key="AddBot"
            size={112}
            caption=""
            className="cursor-pointer"
            imageUrl={addBotImage}
            onClick={() => void addBot()}
          />
        )}
      </div>
      <HostButton label="Let's go!" className="mb-2" onClick={() => startGame()} />

      {isHost && (
        <div className="mt-4 text-center flex flex-col gap-px">
          <details>
            <summary className="mb-3">Advanced settings</summary>
            {gameMode === "v1" ? (
              <HostButton
                className={twAdvancedButton}
                label="Switch to rating based game mode"
                onClick={() => $gameMode.set("v2")}
              />
            ) : (
              <HostButton
                className={twAdvancedButton}
                label="Switch to vote based game mode"
                onClick={() => $gameMode.set("v1")}
              />
            )}
            {range(0, numRounds - 1).map((round) => {
              return (
                <HostButton
                  key={round}
                  className={twAdvancedButton}
                  label={`Set custom prompt for round ${round + 1}`}
                  onClick={async () => {
                    const res = await Swal.fire({
                      input: "text",
                      title: `Round ${round + 1} custom prompt `,
                      inputLabel: `Use a triple underscore (like this: ___) for the fill-in-the-blank part of the prompt.`,
                      inputValue: $themes.getItem(round),
                      showCancelButton: true,
                      inputValidator: (value) => {
                        if (!value) return
                        if (!value.includes(promptTemplatePlaceholder))
                          return "Prompt must contain triple underscore"
                        return
                      },
                    })
                    if (res.isDismissed) return
                    $themes.setItem(round, res.value)
                  }}
                />
              )
            })}
          </details>
        </div>
      )}
    </div>
  )
}

async function addBot() {
  if ($botIds.size >= 5) {
    void Swal.fire({
      text: "You've reached the maximum number of bots",
    })
    return
  }
  const botId = "~" + nanoid(8)
  const {value: botName} = await Swal.fire<string>({
    text: "Bot name:",
    showCancelButton: true,
    input: "text",
    inputValue: sample(botNames, 1)[0],
  })
  if (!botName) return
  $botIds.add(botId)
  $names.setItem(botId, botName)
  const unusedAvatarIds = new Set(AVATAR_IDS)
  for (const id of $avatars.get().values()) {
    unusedAvatarIds.delete(id)
  }
  const avatarId = sample([...unusedAvatarIds], 1)[0]!
  $avatars.setItem(botId, avatarId)
}
